<template>
  <div class="block">
    <div :key="block.id" class="blocks">
      <div class="block-item">
      <div class="block-title" v-if="!isEdit">Блок {{ block.number }}</div>
      <input type="text"
             class="block-input"
             placeholder="Введите название блока"
             ref="newBlock" v-if="isEdit"
             v-model="blockName">
      <div class="create-block-btn" v-if="isEdit" @click="updateBlock">
        <img src="../../assets/images/add-button.svg" alt="">
      </div>
      <div class="cancel-block-btn" v-if="isEdit" @click="isEdit = false;show = true;blockName ='';">
        <img src="../../assets/images/cancel-btn.svg" alt="">
      </div>

      <div class="edit-block" @click="showUpdateBlock" v-if="!isEdit">
        <img src="../../assets/images/edit-button.svg" alt="">
      </div>
      <div class="delete-block" @click="toggleDeleteBlock" v-if="!isEdit">
        <img src="../../assets/images/cancel-btn.svg" alt="">
      </div>
      </div>
    </div>
    <DeletionConfirm v-if="showDeleteBlock"
    title="Вы действительно хотите удалить этот блок?"
    :description="`${block.number} блок`"
    :block = block
    @close="toggleDeleteBlock"
    @delete="deleteBlock"
    />
  </div>
</template>

<script>
import DeletionConfirm from "./DeletionConfirm";
export default {
  name: "BlockItem",
  components: {DeletionConfirm},
  props:{
    block:{
      type: Object,
      default: () => {},
    },
    isEditBlock:{
      type:Boolean,
      default:false,
    },
  },
  data: () =>({
    show:false,
    isEdit:false,
    blockName:"",
    showDeleteBlock:false,
  }),
  methods:{
    deleteBlock(block){
      this.$emit('delete',block);
      console.log(block);
    },
    showUpdateBlock(){
      this.isEdit = true;
      this.show = false;
      this.$nextTick(() => this.$refs.newBlock.focus());
    },
    updateBlock(){
      let data = {block: this.block.id,number: this.blockName};
      this.$emit('update',data);
      this.isEdit=false;
      this.show=false;
      this.blockName="";
    },
    toggleDeleteBlock(){
      this.showDeleteBlock=!this.showDeleteBlock;
    }
  }
}
</script>

<style scoped lang="scss">
.block-item{
  position: relative;
  align-items: center;
  display: flex;
  height: 25px;
  //background: #64dca2;
  &:hover{
    .edit-block{
      display:block;
    }
    .delete-block{
      display: block;
    }
  }
  .delete-block{
    display: none;
  }
  .edit-block{
    display: none;
  }

}
</style>